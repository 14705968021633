import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function Occupancy({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {


  const occupancyTypes = [
    "Administration",
    "Process",
    "Retail",
    "Hospitality",
    "Other",
  ];
  const conditionTypes = [
    "Adequate", "Improvement Needed", "Inadequate",
  ];
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const newinputArr = [...formData.construction_page.construction_type].map((item, index) => {
        console.log("item", item);
        if (formData.occupancy_page.occupancy_items.length) {
          return {
            id: item?.id,
            building:
              formData?.occupancy_page?.occupancy_items[index]?.building ||
              item?.name,
            year:
              formData?.occupancy_page?.occupancy_items[index]?.year ||
              item?.year,
            occupancy:
              formData?.occupancy_page?.occupancy_items[index]?.occupancy ||
              item?.occupancy,
            condition:
              formData?.occupancy_page?.occupancy_items[index]?.condition ||
              item?.condition,
            comment:
              formData?.occupancy_page?.occupancy_items[index]?.comment ||
              item?.comment,
          };
        } else {
          return {
            id: item.id,
            building: item.name,
            occupancy: "",
            year: "",
            condition: "",
            comment: "",
          };
        }
      })
  const [arr, setArr] = useState(newinputArr);

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const changeCommentData = (value, id) => {
    const objIndex = arr?.findIndex((obj) => obj?.id === id);
    arr[objIndex]["comment"] = value;

    setArr([...arr]);
  };
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/occupancy/${state.id}`, {
        occupancy_items: arr,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Occupancy saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Occupancy saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Occupancy already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Occupancy already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Occupancy already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Occupancy already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Occupancy already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Occupancy</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    {arr?.map((item, i) => {
                      return (
                        <>
                          {" "}
                          <Grid item xs={4.2}>
                            <p className="inputTextH">Building</p>{" "}
                          </Grid>
                          <Grid item xs={2.4}>
                            <p className="inputTextH">Occupancy</p>{" "}
                          </Grid>
                          <Grid item xs={2.4}>
                            <p className="inputTextH">Year Built</p>{" "}
                          </Grid>
                          <Grid item xs={3}>
                            <p className="inputTextH">Condition</p>{" "}
                          </Grid>
                          <Grid container item xs={4.2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="building"
                                value={item?.building}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid item xs={2.4}>
                            <Autocomplete
                              hiddenLabel
                              id="occupancy"
                              name="occupancy"
                              size="small"
                              value={item.occupancy}
                              options={occupancyTypes}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "occupancy", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={2.4}>
                            <InputFeild
                              name="year"
                              value={item?.year}
                              onChange={(e) =>
                                changeFormData(
                                  e?.target?.value,
                                  "year",
                                  item?.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                            />{" "}
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              hiddenLabel
                              id="condition"
                              name="condition"
                              size="small"
                              value={item.condition}
                              options={conditionTypes}
                              getOptionLabel={(option) => option}
                              onChange={(event, newValue) => {
                                changeFormData(newValue, "condition", item.id);
                              }}
                              renderInput={(params) => (
                                <TextField size="small" {...params} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            {" "}
                            <p className="inputTextH">Comment</p>{" "}
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                            <ReactQuill
                              modules={module}
                              theme="snow"
                              value={item?.comment}
                              style={{ marginBottom: "25px", height: "200px" }}
                              onChange={(e) => {
                                changeCommentData(e, item.id);
                              }}
                            />
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={10}
                  spacing={1}
                  style={{ marginTop: "2rem" }}
                >
                  <Grid item xs={2}>
                    <p className="inputTextH">Building</p>{" "}
                  </Grid>

                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.occupancy_page, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        callingBackend ||
                        !arr?.every(
                          (item) =>
                            !Object?.entries(item)?.some(
                              ([key, value]) =>
                                value === "" || undefined || null
                            )
                        )
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
