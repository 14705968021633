import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const labels = ["Towns Main", "Towns Main & Pump", "Pump & Tank"];

export default function HydrantSystem({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
  handleTwoPreviousPage,
}) {
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const inputArr = [
    {
      id: 0,
      area_protected: "",
      demand_flow: "",
      demand_pressure: "",
      water_supply_type: "",
      capacity: "",
    },
  ];
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const [arr, setArr] = useState(
    formData?.hydrant_system?.hydrant?.length
      ? [...formData?.hydrant_system?.hydrant]
      : inputArr
  );
  const [number, setNumber] = useState(
    formData?.hydrant_system?.hydrant?.length
      ? formData?.hydrant_system?.hydrant?.length
      : 1
  );
  const [checkPS, setCheckPS] = useState(
    formData?.hydrant_system?.hydrant_system_skip ? true : false
  );
  const [comment, setComment] = useState(
    formData?.hydrant_system?.comment ? formData?.hydrant_system?.comment : ""
  );

  const addInput = () => {
    setNumber(number + 1);
    setArr((s) => {
      return [
        ...s,
        {
          id: number,
          area_protected: "",
          demand_flow: "",
          demand_pressure: "",
          water_supply_type: "",
          capacity: "",
        },
      ];
    });
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
  const handleProtectionSystem = (event) => {
    setCheckPS(event.target.checked);
  };
  const submit = async (e) => {
    const updatedSprinklerSystems = arr.map((system) => {
      if (checkPS) {
        return {
          ...system,
          demand_flow: "0",
          demand_pressure: "0",
        };
      } else {
        return {
          ...system,
          demand_flow:
            system.demand_flow === undefined ? "0" : system.demand_flow,
          demand_pressure:
            system.demand_pressure === undefined ? "0" : system.demand_pressure,
        };
      }
    });
    const hydrantskip = [...formData?.hydrant_system?.hydrant].map(
      (item, index) => {
        if (formData?.hydrant_system_reliability?.hydrant?.length) {
          return {
            id: item?.id,
            hydrant_name:
              formData?.hydrant_system_reliability?.hydrant[index]
                ?.hydrant_name || item?.area_protected,
            reliability:
              formData?.hydrant_system_reliability?.hydrant[index]
                ?.reliability || item?.reliability,
          };
        } else {
          return {
            id: item?.id,
            hydrant_name: item?.area_protected,
            reliability: "",
          };
        }
      }
    );
    const updatedSprinkler = hydrantskip.map((system) => {
      return {
        ...system,
        reliability: "Unknown",
      };
    });
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/hydrant_system/${state.id}`, {
        hydrant: updatedSprinklerSystems,
        hydrant_system_skip: checkPS,
        comment: comment,
      });
      if (formData?.assessment?.water_supply_exclude && checkPS) {
        await axios.put(`/assesment/hydrant_reliability/${state.id}`, {
          sprinkler: updatedSprinkler,
          comment:
            "A Risk Improvement for the site's water supply has been recommended to assess the adequacy and reliability of its hydrant system",
        });
      }
      if (formData?.assessment?.water_supply_exclude && !checkPS) {
        await axios.put(`/assesment/hydrant_reliability/${state.id}`, {
          sprinkler: updatedSprinkler,
          comment:
            "A Risk Improvement to provide hydrant water supplies for engineering analysis has been recommended for this site.",
        });
      }
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Hydrant system created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Hydrant system created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Hydrant system already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Hydrant system already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Hydrant system already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Hydrant system already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Hydrant system already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Hydrant Systems</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={checkPS}
                            onChange={(e) => {
                              handleProtectionSystem(e);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Protection System data disable"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid container item xs={3} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Area Protected</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={3} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Water Supply Type</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Capacity</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Demand Flow (L/min)</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={2} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Demand Pressure (kPa)</p>{" "}
                      </Grid>
                    </Grid>
                    {arr.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="area_protected"
                                value={item.area_protected}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "area_protected",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={3} spacing={1}>
                            <Grid item xs={12}>
                              <Autocomplete
                                hiddenLabel
                                id="water_supply_type"
                                name="water_supply_type"
                                size="small"
                                value={item.water_supply_type}
                                options={labels}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  changeFormData(
                                    newValue,
                                    "water_supply_type",
                                    item.id
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField size="small" {...params} />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="capacity"
                                value={item.capacity}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "capacity",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="demand_flow"
                                value={checkPS ? "0" : item.demand_flow}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "demand_flow",
                                    item.id
                                  )
                                }
                                disabled={checkPS}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={2} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="demand_pressure"
                                value={checkPS ? "0" : item.demand_pressure}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "demand_pressure",
                                    item.id
                                  )
                                }
                                disabled={checkPS}
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                              />{" "}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Button
                      onClick={addInput}
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        marginTop: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                      disabled={
                        number >= 5 ||
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      Add Field
                    </Button>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    sx={{ marginTop: "20px" }}
                  >
                    <p className="inputTextH">
                      Street Main Size and Location Comment
                    </p>{" "}
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: "50px" }}>
                    <ReactQuill
                      modules={module}
                      theme="snow"
                      value={comment}
                      style={{ marginBottom: "25px", height: "200px" }}
                      onChange={setComment}
                    />
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(formData?.hydrant_system, null, 2)}
                    </pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) =>
                                item.water_supply_type === "" ||
                                item.capacity === "" ||
                                item.another_property === ""
                            )
                        ) ||
                        number === 0 ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
