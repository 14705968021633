import React, { useEffect, useState } from "react";
import { Grid, Button, Typography } from "@mui/material";
import axiosSecond from "../../libs/axios";
import axios from "axios";
import { useLocation } from "react-router-dom";
import loadingGif from "../../assets/img/loader.gif";
import CircularProgress from "@mui/material/CircularProgress";

//REACT_APP_BE_URL=https://f4sjvimixg.execute-api.us-east-1.amazonaws.com/dev/
const PDFViewer = ({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) => {
  const { state } = useLocation();
  const [assessmentPdf, setAssessmentPdf] = useState(null);
  const [assessmentDownloadPdf, setAssessmentDownloadPdf] = useState(null);

  // const [pdfBlob, setPdfBlob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errormg, setErrormg] = useState(false);

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async () => {
    try {
      setCallingBackend(true);

      await axiosSecond.put(`/assesment/pdf-upload/${state.id}`, {
        pdflink: assessmentPdf,
        downloadlink: assessmentDownloadPdf,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Assessment created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Assessment created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      console.error("Error submitting the URL", error);
    }
  };

  const get = async () => {
    try {
      const priorityar = formData?.priority?.priorityri?.filter(
        (item) => item?.priority !== "Closed"
      );
      const pfirst = priorityar?.filter((item, i) => i === 0);
      const parrs = priorityar?.filter(
        (item, i) => i !== 0 && i !== priorityar.length - 1
      );
      const parrtrue = parrs.length ? true : false;
      const plast = priorityar?.filter(
        (item, i) => i !== 0 && i === priorityar.length - 1
      );
      const plasttrue = plast.length ? true : false;

      const parr = { pfirst, parrs, plast };

      const prioritynotclosed = formData?.priority?.priorityri?.filter(
        (item) => item?.priority !== "Closed"
      );
      const priorityclosed = formData?.priority?.priorityri?.filter(
        (item) => item?.priority === "Closed"
      );
      const prioritydayar = formData?.priority?.priorityri?.filter(
        (item) => item?.client_response === "Will Be Completed In 90 Days"
      );
      const pdayfirst = prioritydayar?.filter((item, i) => i === 0);
      const pday = prioritydayar?.filter(
        (item, i) => i !== 0 && i !== prioritydayar.length - 1
      );
      const pdaytrue = pday.length ? true : false;
      const pdaylast = prioritydayar?.filter(
        (item, i) => i !== 0 && i === prioritydayar.length - 1
      );
      const pdlasttrue = pdaylast.length ? true : false;
      const pdays = { pdayfirst, pday, pdaylast };
      const { data } = await axiosSecond?.get(`/ri/`);

      const priorityRISet = formData?.priority?.priorityri?.map((item) => {
        if (item?.ri < 50) {
          const datanew = data?.find((i) => i?.rinum === item?.ri);
          return {
            ...item,
            theRisk: datanew?.theRisk,
            detail: datanew?.detail,
          };
        } else {
          const datanew = formData?.new_ri?.ri?.find((i) => i?.ri === item?.ri);
          return {
            ...item,
            theRisk: datanew?.theRisk,
            detail: datanew?.details,
          };
        }
      });
      const priorityRI = priorityRISet?.map((item) => {
        if (item?.ri === 39) {
          const datanew = formData?.special_hazard?.hazard?.find(
            (i) => i?.rioption?.title === item?.title
          );
          return {
            ...item,
            theRisk: datanew?.rioption?.theRisk,
            detail: datanew?.rioption?.details,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      const newbasic_detailsfirst = formData?.basic_details?.section1
        ?.filter((item) => item.id === 0)
        ?.map((item) => {
          return {
            ...item,
            value: item?.value?.toLocaleString(),
          };
        });
      const newbasic_details = formData?.basic_details?.section1
        ?.filter(
          (item) =>
            (item.id !== 0 && item?.name !== "Other") ||
            (item?.name === "Other" && item?.value !== 0)
        )
        ?.map((item) => {
          return {
            ...item,
            value: item?.value?.toLocaleString(),
          };
        });

      const watersupplyar = formData?.water_supply?.place?.map((item) => {
        const datanew = formData?.sprinkler_system_reliability?.sprinkler?.find(
          (i) => i?.id === item?.id
        );
        if (formData?.water_supply?.water_supply_skip) {
          return {
            ...item,
            reliability: datanew?.reliability,
            demand_point: "Unknown",
          };
        } else {
          return {
            ...item,
            reliability: datanew?.reliability,
            demand_point: `${item?.demand_flow}L/min @ ${item?.demand_pressure}kPa`,
          };
        }
      });

      const wf = watersupplyar?.filter((item) => item.id === 0);
      const w = watersupplyar?.filter(
        (item) => item.id !== 0 && item.id !== watersupplyar.length - 1
      );
      const wtrue = w.length ? true : false;

      const wl = watersupplyar?.filter(
        (item) => item.id !== 0 && item.id === watersupplyar.length - 1
      );
      const wltrue = wl.length ? true : false;

      const sf = formData?.sprinklers?.protection
        ?.filter((item) => item.id === 0)
        .map((item) => {
          return {
            ...item,
            ap: item?.area_protection,
            ps: item?.percentage_of_site,
          };
        });
      const s = formData?.sprinklers?.protection
        ?.filter(
          (item) =>
            item.id !== 0 &&
            item.id !== formData?.sprinklers?.protection.length - 1
        )
        .map((item) => {
          return {
            ...item,
            ap: item?.area_protection,
            ps: item?.percentage_of_site,
          };
        });
      const strue = s.length ? true : false;

      const sl = formData?.sprinklers?.protection
        ?.filter(
          (item) =>
            item.id !== 0 &&
            item.id === formData?.sprinklers?.protection.length - 1
        )
        .map((item) => {
          return {
            ...item,
            ap: item?.area_protection,
            ps: item?.percentage_of_site,
          };
        });
      const sltrue = sl.length ? true : false;

      const hydrantsystemar = formData?.hydrant_system?.hydrant?.map((item) => {
        const datanew = formData?.hydrant_system_reliability?.hydrant?.find(
          (i) => i?.id === item?.id
        );
        if (formData?.hydrant_system?.hydrant_system_skip) {
          return {
            ...item,
            reliability: datanew?.reliability,
            demand_point: "Unknown",
          };
        } else {
          return {
            ...item,
            reliability: datanew?.reliability,
            demand_point: `${item?.demand_flow}L/min @ ${item?.demand_pressure}kPa`,
          };
        }
      });
      const townmain = formData?.town_main?.town_mains_check
        ? [{ demand_point: "Unknown" }]
        : formData?.town_main?.town_mains?.map((item) => ({
            ...item,
            demand_point: `${item?.sprinkler_flow}L/min @ ${item?.discharge}kPa`,
          }));
      const hydrants = formData?.town_main?.hydrant_check
        ? [{ demand_point: "Unknown" }]
        : formData?.town_main?.hydrant?.map((item) => ({
            ...item,
            demand_point: `${item?.hydrant_flow}L/min @ ${item?.hydrant_test_location}kPa`,
          }));
      const pumpdata1 = formData?.pump1?.pump_performance?.map((item) => {
        if (formData?.pump1?.pump_performance.length) {
          return {
            ...item,
            demand_point: `${item?.sprinkler_pump_flow}L/min @ ${item?.discharge}kPa`,
          };
        } else {
          return {
            ...item,
            demand_point: "Unknown",
          };
        }
      });
      const pumpdata2 = formData?.pump2?.pump_performance?.map((item) => {
        if (formData?.pump2?.pump_performance.length) {
          return {
            ...item,
            demand_point: `${item?.sprinkler_pump_flow}L/min @ ${item?.discharge}kPa`,
          };
        } else {
          return {
            ...item,
            demand_point: "Unknown",
          };
        }
      });
      const pumpdata3 = formData?.pump3?.pump_performance?.map((item) => {
        if (formData?.pump3?.pump_performance.length) {
          return {
            ...item,
            demand_point: `${item?.sprinkler_pump_flow}L/min @ ${item?.discharge}kPa`,
          };
        } else {
          return {
            ...item,
            demand_point: "Unknown",
          };
        }
      });
      const pumpdata4 = formData?.pump4?.pump_performance?.map((item) => {
        if (formData?.pump4?.pump_performance.length) {
          return {
            ...item,
            demand_point: `${item?.sprinkler_pump_flow}L/min @ ${item?.discharge}kPa`,
          };
        } else {
          return {
            ...item,
            demand_point: "Unknown",
          };
        }
      });

      const hf = hydrantsystemar.filter((item) => item.id === 0);
      const h = hydrantsystemar?.filter(
        (item) => item.id !== 0 && item.id !== hydrantsystemar.length - 1
      );
      const htrue = h.length ? true : false;
      const hl = hydrantsystemar?.filter(
        (item) => item.id !== 0 && item.id === hydrantsystemar.length - 1
      );
      const hltrue = hl.length ? true : false;
      const mfl_property_damage =
        formData?.mfl_calculation?.total_of_mflfire?.toLocaleString() || 0;
      const mfl_bi =
        formData?.mfl_calculation?.total_of_mfl_bi?.toLocaleString() || 0;
      const mfl_total =
        formData?.mfl_calculation?.total_of_loss?.toLocaleString() || 0;
      const mfl_other =
        formData?.liability?.total_of_mfl?.toLocaleString() || 0;
      const nle_property_damage =
        formData?.nle_calculation?.total_of_nlefire?.toLocaleString() || 0;
      const nle_bi =
        formData?.nle_calculation?.total_of_nle_bi?.toLocaleString() || 0;
      const nle_total =
        formData?.nle_calculation?.total_of_loss?.toLocaleString() || 0;
      const nle_other =
        formData?.liability?.total_of_nle?.toLocaleString() || 0;
      const wle_property_damage =
        formData?.wle_calculation?.total_of_wlefire?.toLocaleString() || 0;
      const wle_bi =
        formData?.wle_calculation?.total_of_wle_bi?.toLocaleString() || 0;
      const wle_total =
        formData?.wle_calculation?.total_of_loss?.toLocaleString() || 0;
      const wle_other =
        (formData?.wleliability?.wle_edit
          ? formData?.wleliability?.total_of_wle?.toLocaleString()
          : formData?.liability?.total_of_wle?.toLocaleString()) || 0;
      const basic_details_s1_total =
        formData?.basic_details?.section1_total?.toLocaleString() || 0;
      const basic_details_s2_total =
        formData?.basic_details?.section2_total?.toLocaleString() || 0;
      const basic_details_s1_s2_total =
        formData?.basic_details?.total_s1_s2?.toLocaleString() || 0;
      const basic_details_bi =
        formData?.basic_details?.business_interuption?.toLocaleString() || 0;
      const basic_details_bi_o_ip =
        formData?.basic_details?.bi_over_ip?.toLocaleString() || 0;

      function convertUlToHyphens(htmlString) {
        return htmlString.replace(/<ul>(.*?)<\/ul>/gs, (match) => {
          const innerContent = match
            .replace(/<\/?ul>/g, "")
            .replace(/<li>/g, "- ")
            .replace(/<\/li>/g, "<br>");

          return innerContent;
        });
      }

      const heading1 = convertUlToHyphens(
        formData?.executive_summary?.heading1
          ? formData?.executive_summary?.heading1
          : ""
      );
      const heading2 = convertUlToHyphens(
        formData?.executive_summary?.heading2
          ? formData?.executive_summary?.heading2
          : ""
      );
      const heading3 = convertUlToHyphens(
        formData?.executive_summary?.heading3
          ? formData?.executive_summary?.heading3
          : ""
      );
      const heading4 = convertUlToHyphens(
        formData?.executive_summary?.heading4
          ? formData?.executive_summary?.heading4
          : ""
      );
      const heading5 = convertUlToHyphens(
        formData?.executive_summary?.heading5
          ? formData?.executive_summary?.heading5
          : ""
      );
      const heading6 = convertUlToHyphens(
        formData?.executive_summary?.heading6
          ? formData?.executive_summary?.heading6
          : ""
      );
      const existing_risk = convertUlToHyphens(
        formData?.executive_summary?.existing_risk
          ? formData?.executive_summary?.existing_risk
          : ""
      );

      const significant_exposures = convertUlToHyphens(
        formData?.executive_summary?.significant_exposures
          ? formData?.executive_summary?.significant_exposures
          : ""
      );
      const significant_changes_recent = convertUlToHyphens(
        formData?.executive_summary?.significant_changes_recent
          ? formData?.executive_summary?.significant_changes_recent
          : ""
      );
      const significant_changes_future = convertUlToHyphens(
        formData?.executive_summary?.significant_changes_future
          ? formData?.executive_summary?.significant_changes_future
          : ""
      );
      const wle_comment = convertUlToHyphens(
        formData?.wle_calculation?.comment
          ? formData?.wle_calculation?.comment
          : ""
      );
      const nle_comment = convertUlToHyphens(
        formData?.nle_calculation?.comment
          ? formData?.nle_calculation?.comment
          : ""
      );
      const mfl_comment = convertUlToHyphens(
        formData?.mfl_calculation?.comment
          ? formData?.mfl_calculation?.comment
          : ""
      );

      const valuable_commodities = convertUlToHyphens(
        formData?.site_security?.valuable_commodities
          ? formData?.site_security?.valuable_commodities
          : ""
      );
      const yard_storage = convertUlToHyphens(
        formData?.site_security?.yard_storage
          ? formData?.site_security?.yard_storage
          : ""
      );
      const gates_fencing_barriers = convertUlToHyphens(
        formData?.site_security?.gates_fencing_barriers
          ? formData?.site_security?.gates_fencing_barriers
          : ""
      );
      const lighting = convertUlToHyphens(
        formData?.site_security?.lighting
          ? formData?.site_security?.lighting
          : ""
      );
      const cctv = convertUlToHyphens(
        formData?.site_security?.cctv ? formData?.site_security?.cctv : ""
      );
      const security_patrols = convertUlToHyphens(
        formData?.site_security?.security_patrols
          ? formData?.site_security?.security_patrols
          : ""
      );
      const alarms = convertUlToHyphens(
        formData?.site_security?.alarms ? formData?.site_security?.alarms : ""
      );
      const othercomment = convertUlToHyphens(
        formData?.site_security?.other ? formData?.site_security?.other : ""
      );
      const north = convertUlToHyphens(
        formData?.external_exposures?.north?.comments1
          ? formData?.external_exposures?.north?.comments1
          : ""
      );
      const east = convertUlToHyphens(
        formData?.external_exposures?.east?.comments2
          ? formData?.external_exposures?.east?.comments2
          : ""
      );
      const south = convertUlToHyphens(
        formData?.external_exposures?.south?.comments3
          ? formData?.external_exposures?.south?.comments3
          : ""
      );
      const west = convertUlToHyphens(
        formData?.external_exposures?.west?.comments4
          ? formData?.external_exposures?.west?.comments4
          : ""
      );
      const alarmtype = convertUlToHyphens(
        formData?.fire_reponse?.protection_system?.alarm_type
          ? formData?.fire_reponse?.protection_system?.alarm_type
          : ""
      );
      const initiatedby = convertUlToHyphens(
        formData?.fire_reponse?.protection_system?.initiated_by
          ? formData?.fire_reponse?.protection_system?.initiated_by
          : ""
      );
      const maintenance = convertUlToHyphens(
        formData?.fire_reponse?.protection_system?.maintenance
          ? formData?.fire_reponse?.protection_system?.maintenance
          : ""
      );
      const brigadetype = convertUlToHyphens(
        formData?.fire_reponse?.fire_brigade?.fire_brigade_type
          ? formData?.fire_reponse?.fire_brigade?.fire_brigade_type
          : ""
      );
      const distancesite = convertUlToHyphens(
        formData?.fire_reponse?.fire_brigade?.distance_from_site
          ? formData?.fire_reponse?.fire_brigade?.distance_from_site
          : ""
      );
      const lastvisited = convertUlToHyphens(
        formData?.fire_reponse?.fire_brigade?.last_visited
          ? formData?.fire_reponse?.fire_brigade?.last_visited
          : ""
      );
      const fireextinguishers = convertUlToHyphens(
        formData?.fire_reponse?.extinguishers_and_hose_reels?.fire_extinguishers
          ? formData?.fire_reponse?.extinguishers_and_hose_reels
              ?.fire_extinguishers
          : ""
      );
      const hosereels = convertUlToHyphens(
        formData?.fire_reponse?.extinguishers_and_hose_reels?.hose_reels
          ? formData?.fire_reponse?.extinguishers_and_hose_reels?.hose_reels
          : ""
      );
      const fddescription = convertUlToHyphens(
        formData?.fire_detection?.fire_detection_comment
          ? formData?.fire_detection?.fire_detection_comment
          : ""
      );
      const sprinklercomment = convertUlToHyphens(
        formData?.sprinkler_system_reliability?.comment
          ? formData?.sprinkler_system_reliability?.comment
          : ""
      );

      const sprinkcomment = convertUlToHyphens(
        formData?.sprinklers?.comment ? formData?.sprinklers?.comment : ""
      );
      const powersupply = convertUlToHyphens(
        formData?.essential_service?.power_supply
          ? formData?.essential_service?.power_supply
          : ""
      );
      const gassupply = convertUlToHyphens(
        formData?.essential_service?.gas_supply
          ? formData?.essential_service?.gas_supply
          : ""
      );
      const industrialheating = convertUlToHyphens(
        formData?.essential_service?.industrial_heating
          ? formData?.essential_service?.industrial_heating
          : ""
      );
      const refrigeration = convertUlToHyphens(
        formData?.essential_service?.refrigeration
          ? formData?.essential_service?.refrigeration
          : ""
      );
      const essentialserviceother = convertUlToHyphens(
        formData?.essential_service?.other
          ? formData?.essential_service?.other
          : ""
      );
      const numberofemployees = convertUlToHyphens(
        formData?.operational_overview?.number_of_employees
          ? formData?.operational_overview?.number_of_employees
          : ""
      );
      const daysperweek = convertUlToHyphens(
        formData?.operational_overview?.days_per_week
          ? formData?.operational_overview?.days_per_week
          : ""
      );
      const seasonality = convertUlToHyphens(
        formData?.operational_overview?.seasonality
          ? formData?.operational_overview?.seasonality
          : ""
      );
      const oodescription = convertUlToHyphens(
        formData?.operational_overview?.description
          ? formData?.operational_overview?.description
          : ""
      );
      function processHazards(hazards) {
        return hazards.map((item) => {
          const updatedDescription = convertUlToHyphens(
            item?.description ? item?.description : ""
          );

          return {
            ...item,
            description: updatedDescription,
          };
        });
      }
      const shazard = processHazards(formData?.special_hazard?.hazard);
      const hazardar = shazard?.filter(
        (item) => item?.name !== "" || item?.type?.name !== ""
      );
      const hazardarrfirst = hazardar?.filter((item) => item.id === 0);
      const hazardarr = hazardar?.filter(
        (item) => item.id !== 0 && item.id !== hazardar.length - 1
      );
      const hazardarrlast = hazardar?.filter(
        (item) => item.id !== 0 && item.id === hazardar.length - 1
      );
      const nhexposurear = processHazards(
        formData?.natural_hazards_exposure?.n_h_exposure
      );
      const nhexposurefirst = nhexposurear?.filter((item) => item.id === 0);
      const nhexposurearr = nhexposurear?.filter(
        (item) => item.id !== 0 && item.id !== nhexposurear.length - 1
      );
      const nhexposurelast = nhexposurear?.filter(
        (item) => item.id !== 0 && item.id === nhexposurear.length - 1
      );
      const lastTwoMPractice =
        formData?.management_practice?.m_practice?.slice(-2);
      const filteredFireDetection =
        formData?.fire_detection_protection?.f_d_protection
          ?.filter((item) => item?.id === 6 || item?.id === 9)
          .map((item) => ({
            ...item,
            description: "",
          }));
      const m_practice_reactivearr = processHazards(lastTwoMPractice);

      const m_practice_reactivear = [
        ...m_practice_reactivearr,
        ...filteredFireDetection,
      ];
      const mprf = m_practice_reactivear?.filter((item, i) => i === 0);
      const mpr = m_practice_reactivear?.filter(
        (item, i) => i !== 0 && i !== m_practice_reactivear.length - 1
      );
      const mprl = m_practice_reactivear?.filter(
        (item, i) => i !== 0 && i === m_practice_reactivear.length - 1
      );

      const m_practice_proactivearr =
        formData?.management_practice?.m_practice?.filter(
          (item, index) =>
            index < formData?.management_practice?.m_practice?.length - 2
        );
      const m_practice_proactivear = processHazards(m_practice_proactivearr);
      const mppf = m_practice_proactivear?.filter((item, i) => i === 0);
      const mpp = m_practice_proactivear?.filter(
        (item, i) => i !== 0 && i !== m_practice_proactivear.length - 1
      );
      const mppl = m_practice_proactivear?.filter(
        (item, i) => i !== 0 && i === m_practice_proactivear.length - 1
      );
      const o_overviewarr = formData?.construction_page?.construction_type?.map(
        (item) => {
          const datanew = formData?.occupancy_page?.occupancy_items?.find(
            (i) => i?.building === item?.name
          );
          return {
            ...item,
            occupancy: datanew?.occupancy,
            year: datanew?.year,
            condition: datanew?.condition,
            description: datanew?.comment,
          };
        }
      );
      const constructionarr =
        formData?.construction_page?.construction_type?.map((item) => {
          return {
            ...item,
            description: item?.comment,
          };
        });
      const newconstructionarr = processHazards(constructionarr);
      const cf = newconstructionarr
        ?.filter((item) => item.id === 0)
        .map((item) => ({
          ...item,
          n: item.name,
          l: item.level,
          r: item.roof,
          f: item.floor,
          iw: item.internal_wall,
          ew: item.external_wall,
          fr: item.fram,
        }));
      const c = newconstructionarr
        ?.filter(
          (item) => item.id !== 0 && item.id !== newconstructionarr.length - 1
        )
        .map((item) => ({
          ...item,
          n: item.name,
          l: item.level,
          r: item.roof,
          f: item.floor,
          iw: item.internal_wall,
          ew: item.external_wall,
          fr: item.fram,
        }));
      const ctrue = c.length ? true : false;

      const cl = newconstructionarr
        ?.filter(
          (item) => item.id !== 0 && item.id === newconstructionarr.length - 1
        )
        .map((item) => ({
          ...item,
          n: item.name,
          l: item.level,
          r: item.roof,
          f: item.floor,
          iw: item.internal_wall,
          ew: item.external_wall,
          fr: item.fram,
        }));
      const cltrue = cl.length ? true : false;

      const o_overview_arr = processHazards(o_overviewarr);
      const of = o_overview_arr
        ?.filter((item) => item.id === 0)
        ?.map((item) => {
          return {
            ...item,
            value: item?.value?.toLocaleString(),
            n: item.name,
            oc: item.occupancy,
            y: item.year,
            p: item.area_percentage,
            e: item.eps.name,
            a: item.apc.name,
            t: item.total_adequate_percentage,
          };
        });
      const o = o_overview_arr
        ?.filter(
          (item) => item.id !== 0 && item.id === o_overview_arr.length - 1
        )
        ?.map((item) => {
          return {
            ...item,
            value: item?.value?.toLocaleString(),
            n: item.name,
            oc: item.occupancy,
            y: item.year,
            p: item.area_percentage,
            e: item.eps.name,
            a: item.apc.name,
            t: item.total_adequate_percentage,
          };
        });
      const otrue = o.length ? true : false;
      const ol = o_overview_arr
        ?.filter((item) => item.id !== 0 && item.id === o_overview_arr.length - 1)
        ?.map((item) => {
          return {
            ...item,
            value: item?.value?.toLocaleString(),
            n: item.name,
            oc: item.occupancy,
            y: item.year,
            p: item.area_percentage,
            e: item.eps.name,
            a: item.apc.name,
            t: item.total_adequate_percentage,
          };
        });
      const oltrue = ol.length ? true : false;
      function processImprovements(improvements) {
        return improvements.map((improvementObj) => {
          const { improvment } = improvementObj;

          const updatedTheRisk = convertUlToHyphens(
            improvment.theRisk ? improvment.theRisk : ""
          );
          const updatedDetail = convertUlToHyphens(
            improvment.detail ? improvment.detail : ""
          );
          const updatedResponse = convertUlToHyphens(
            improvment.client_response ? improvment.client_response : ""
          );
          const updatedClientComment = convertUlToHyphens(
            improvment.client_comment ? improvment.client_comment : ""
          );

          return {
            ...improvementObj,
            improvment: {
              ...improvment,
              theRisk: updatedTheRisk,
              detail: updatedDetail,
              client_response: updatedResponse,
              client_comment: updatedClientComment,
            },
          };
        });
      }

      const riskImproments = priorityRI?.map((item) => {
        return { improvment: { ...item } };
      });
      const riskImpromentsobj = processImprovements(riskImproments);
      const fdf = formData?.fire_detection?.fire_detection_page?.filter(
        (item) => item.id === 0
      );
      const fd = formData?.fire_detection?.fire_detection_page?.filter(
        (item) =>
          item.id !== 0 &&
          item.id === formData?.fire_detection?.fire_detection_page.length - 1
      );
      const fdtrue = fd.length ? true : false;
      const fdl = formData?.fire_detection?.fire_detection_page?.filter(
        (item) =>
          item.id !== 0 &&
          item.id === formData?.fire_detection?.fire_detection_page.length - 1
      );
      const fdltrue = fdl.length ? true : false;

      const json = {
        ...formData,
        sprinklercomment,
        townmain,
        hydrants,
        hazardarrfirst,
        hazardarrlast,
        fdf,
        fd,
        fdl,
        fdtrue,
        fdltrue,
        parr,
        plasttrue,
        parrtrue,
        priorityclosed,
        priorityRI,
        o,
        oltrue,
        otrue,
        of,
        ol,
        w,
        wl,
        wf,
        wltrue,
        wtrue,
        sf,
        sl,
        sltrue,
        strue,
        s,
        h,
        hl,
        hf,
        htrue,
        hltrue,
        hazardarr,
        prioritynotclosed,
        mprf,
        mprl,
        mpr,
        mpp,
        mppf,
        mppl,
        newbasic_details,
        newbasic_detailsfirst,
        pdays,
        pdaytrue,
        pdlasttrue,
        mfl_property_damage,
        mfl_bi,
        mfl_total,
        mfl_other,
        nle_property_damage,
        nle_bi,
        nle_total,
        nle_other,
        wle_property_damage,
        wle_bi,
        wle_total,
        wle_other,
        basic_details_s1_total,
        basic_details_s2_total,
        basic_details_s1_s2_total,
        basic_details_bi,
        basic_details_bi_o_ip,
        heading1,
        heading2,
        heading3,
        heading4,
        heading5,
        heading6,
        existing_risk,
        significant_exposures,
        significant_changes_recent,
        significant_changes_future,
        wle_comment,
        nle_comment,
        mfl_comment,
        valuable_commodities,
        yard_storage,
        gates_fencing_barriers,
        lighting,
        cctv,
        security_patrols,
        alarms,
        othercomment,
        north,
        east,
        south,
        west,
        alarmtype,
        initiatedby,
        maintenance,
        fireextinguishers,
        hosereels,
        fddescription,
        brigadetype,
        distancesite,
        lastvisited,
        sprinkcomment,
        powersupply,
        gassupply,
        industrialheating,
        refrigeration,
        essentialserviceother,
        numberofemployees,
        daysperweek,
        seasonality,
        oodescription,
        nhexposurearr,
        nhexposurefirst,
        nhexposurelast,
        cf,
        c,
        cl,
        ctrue,
        cltrue,
        pumpdata1,
        pumpdata2,
        pumpdata3,
        pumpdata4,
        riskImproment: { ...riskImpromentsobj },
      };

      console.log("hazardarr", json);

      const response = await axios.post(
        `https://place-risk-api.yivaceylon.com/pdfdownload/`,
        {
        data: json,
      });
      if (response?.data?.message === "success") {
        setTimeout(async () => {
          const { data } = await axios.get(
            `https://place-risk-api.yivaceylon.com/pdfdownload/upload`
          );
          setAssessmentPdf(data?.viewable_url);
          setAssessmentDownloadPdf(data?.downloadable_url);
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
        setErrormg(true);
      }
    } catch (error) {
      console.error("Error occurred while downloading PDF:", error);
      // Handle network errors or other exceptions
    }
  };

  useEffect(() => {
    get();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          padding: "1rem",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p className="formName">PDF Generation</p>
        <Grid
          container
          xs={10}
          spacing={1}
          sx={{ justifyContent: "center", alignContent: "center" }}
        >
          {errormg && (
            <Typography>
              Server error! Check back in a few minutes and try again.
            </Typography>
          )}
          {loading ? (
            <>
              <Grid
                container
                xs={10}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <img src={loadingGif} alt="Loading..." width="400px" />
                <Typography
                  style={{
                    textAlign: "center",
                    position: "absolute",
                    bottom: "100px",
                    width: "100%",
                  }}
                >
                  Please wait while PRS AREA generates your report…
                </Typography>
              </Grid>
            </>
          ) : (
            assessmentPdf && (
              <iframe
                src={assessmentPdf}
                width="100%"
                height="700px"
                title="Assessment Report"
              />
            )
          )}
          <Grid
            container
            item
            xs={12}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              onClick={() => handleClick()}
              disabled={previouscallingBackend}
            >
              {previouscallingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Previous"
              )}
            </Button>
            <Button
              variant="contained"
              onClick={() => submit()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
              disabled={!assessmentPdf || callingBackend}
            >
              {callingBackend ? (
                <CircularProgress
                  size={25}
                  sx={{ color: "#22345B", margin: "0 5px" }}
                />
              ) : (
                "Complete"
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PDFViewer;
