import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function FireResponse({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [quillbar1, setQuillbar1] = useState(
    formData?.fire_reponse?.protection_system?.alarm_type
      ? formData?.fire_reponse?.protection_system?.alarm_type
      : ""
  );
  const [quillbar2, setQuillbar2] = useState(
    formData?.fire_reponse?.protection_system?.initiated_by
      ? formData?.fire_reponse?.protection_system?.initiated_by
      : ""
  );
  const [quillbar3, setQuillbar3] = useState(
    formData?.fire_reponse?.protection_system?.maintenance
      ? formData?.fire_reponse?.protection_system?.maintenance
      : ""
  );
  const [quillbar4, setQuillbar4] = useState(
    formData?.fire_reponse?.fire_brigade?.fire_brigade_type
      ? formData?.fire_reponse?.fire_brigade?.fire_brigade_type
      : ""
  );
  const [quillbar5, setQuillbar5] = useState(
    formData?.fire_reponse?.fire_brigade?.distance_from_site
      ? formData?.fire_reponse?.fire_brigade?.distance_from_site
      : ""
  );
  const [quillbar6, setQuillbar6] = useState(
    formData?.fire_reponse?.fire_brigade?.last_visited
      ? formData?.fire_reponse?.fire_brigade?.last_visited
      : ""
  );
  const [quillbar7, setQuillbar7] = useState(
    formData?.fire_reponse?.extinguishers_and_hose_reels?.fire_extinguishers
      ? formData?.fire_reponse?.extinguishers_and_hose_reels?.fire_extinguishers
      : ""
  );
  const [quillbar8, setQuillbar8] = useState(
    formData?.fire_reponse?.extinguishers_and_hose_reels?.hose_reels
      ? formData?.fire_reponse?.extinguishers_and_hose_reels?.hose_reels
      : ""
  );

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  
  const module = {
    toolbar: toolbarOptions,
  };
  const submit = async () => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/fire_reponse/${state.id}`, {
        alarm_type: quillbar1,
        initiated_by: quillbar2,
        maintenance: quillbar3,
        fire_brigade_type: quillbar4,
        distance_from_site: quillbar5,
        last_visited: quillbar6,
        fire_extinguishers: quillbar7,
        hose_reels: quillbar8,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Response saved successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Response saved successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Response already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Response already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Response already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Response already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Response already exists!",
            }),
          5000
        );
      }
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Other Fire Response </p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH">Protection System</p>{" "}
                    </Grid>

                    <Grid item xs={9}>
                      <p className="inputTextH">Description</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Alarm Type</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar1}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar1}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Initiated By</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        style={{ marginBottom: "25px", height: "100px" }}
                        value={quillbar2}
                        onChange={setQuillbar2}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Maintenance</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        style={{ marginBottom: "25px", height: "100px" }}
                        value={quillbar3}
                        onChange={setQuillbar3}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH">Fire Brigade</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Fire Brigade Type</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        style={{ marginBottom: "25px", height: "100px" }}
                        value={quillbar4}
                        onChange={setQuillbar4}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Distance From Site</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar5}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar5}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Last Visited</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar6}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar6}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextH">Extinguishers and Hose Reels</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Fire Extinguishers</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar7}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar7}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <p className="inputTextP">Hose Reels</p>{" "}
                    </Grid>
                    <Grid item xs={9} sx={{ marginBottom: "50px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={quillbar8}
                        style={{ marginBottom: "25px", height: "100px" }}
                        onChange={setQuillbar8}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.fire_reponse, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => submit()}
                      disabled={
                        !quillbar1 ||
                        !quillbar2 ||
                        !quillbar3 ||
                        !quillbar4 ||
                        !quillbar5 ||
                        !quillbar6 ||
                        !quillbar7 ||
                        !quillbar8 ||
                        quillbar1 === "<p><br></p>" ||
                        quillbar2 === "<p><br></p>" ||
                        quillbar3 === "<p><br></p>" ||
                        quillbar4 === "<p><br></p>" ||
                        quillbar5 === "<p><br></p>" ||
                        quillbar6 === "<p><br></p>" ||
                        quillbar7 === "<p><br></p>" ||
                        quillbar8 === "<p><br></p>" ||
                        callingBackend
                      }
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
