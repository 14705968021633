import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Grid } from "@mui/material";
import axios from "../../libs/axios";
import { useLocation } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function HydrantFlowChart({ text }) {
  const { state } = useLocation();
  const [sprinklersData, setSprinklersData] = useState([]);
  const [hydrantData, setHydrantData] = useState([]);
  const [townMain, setTownMain] = useState([]);
  const [activeDataset, setActiveDataset] = useState(null);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: () => "",
          label: (context) =>
            `Name: ${context.raw.name}, Value: (x:${context.parsed.x}, y:${context.parsed.y})`,
        },
      },
      legend: {
        onClick: (e, legendItem) => {
          const datasetLabel = legendItem.text;
          setActiveDataset((prevDataset) =>
            prevDataset === datasetLabel ? null : datasetLabel
          );
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: "Demand Flow (L/min)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Pressure (kPa)",
        },
      },
    },
  };

  const getDataSets = () => [
    {
      label: "Sprinklers",
      data: sprinklersData.map((item) => ({
        x: item.demand_flow,
        y: item.demand_pressure,
        name: item.area_protected,
      })),
      fill: false,
      borderColor: "#5D84BF",
      backgroundColor: "#5D84BF",
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value.name,
      },
      hidden: activeDataset !== null && activeDataset !== "Sprinklers",
    },
    {
      label: "Hydrants",
      data: hydrantData.map((item) => ({
        x: item.hydrant_flow,
        y: item.hydrant_test_location,
        name: "",
      })),
      fill: false,
      borderColor: "#A5862A",
      backgroundColor: "#A5862A",
      tension: 0.4,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value) => value.name,
      },
      hidden: activeDataset !== null && activeDataset !== "Hydrants",
    },
    {
      label: "Town Main",
      data: townMain.map((item) => ({
        x: item.sprinkler_flow,
        y: item.discharge,
        name: "",
      })),
      fill: false,
      datalabels: {
        display: text,
        align: "right",
        formatter: (value, context) => {
          const xValue =
            context.chart.data.datasets[context.datasetIndex].data[
              context.dataIndex
            ].x;
          const yValue =
            context.chart.data.datasets[context.datasetIndex].data[
              context.dataIndex
            ].y;

          return `x: ${xValue}, y: ${yValue}`;
        },
      },
      borderColor: "#243859",
      backgroundColor: "#243859",
      tension: 0.4,
      hidden: activeDataset !== null && activeDataset !== "Town Main",
    },
  ];

  const data = {
    datasets: getDataSets(),
  };

  const get = async () => {
    try {
      const { data } = await axios.get(`/assesment/${state.id}`);
      if (!data?.town_main?.town_mains_check) {
        console.log("data?.town_main?.town_mains", data?.town_main?.town_mains);
        setTownMain(data?.town_main?.town_mains);
      } else {
        setTownMain([]);
      }
      if (!data?.town_main?.hydrant_check) {
        console.log("data?.town_main?.hydrant_check", data?.town_main?.hydrant);
        setHydrantData(data?.town_main?.hydrant);
      } else {
        setHydrantData([]);
      }
      setSprinklersData(data?.hydrant_system?.hydrant);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <Grid
        container
        xs={12}
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "flex-start",
        }}
      >
        <Grid container item xs={12}>
          <Line options={options} data={data} />
        </Grid>
      </Grid>
    </div>
  );
}
