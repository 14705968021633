import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import axios from "../../libs/axios";
import { Grid, Box, Button, Typography } from "@mui/material";
import FormStepperNav from "./FormStepperNav";
import Drower from "./Drower";
import Pages from "../../pages";
import "./assesment.css";
import Background from "../../../src/assets/img/bg.webp";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../screens/store/actions/authActions";
import Logo from "../../assets/img/logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import prsdark from "../../assets/img/Dark_logomark.png";
import olldark from "../../assets/img/Logomark_W.png";
export default function MainScreen() {
  let navigate = useNavigate();

  let dispatch = useDispatch();
  const { state } = useLocation();
  const [callingBackendcenter, setCallingBackendCenter] = useState(false);
  const [callingBackend, setCallingBackend] = useState(false);
  const [previouscallingBackend, setPreviousCallingBackend] = useState(false);
  const [skipcallingBackend, setSkipCallingBackend] = useState(false);

  const accessToken = sessionStorage.getItem("accessToken");
  if (!accessToken) {
    navigate("/");
  }

  const getPage = async () => {
    try {
      setCallingBackendCenter(true);
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);

      console.log("data", data);
    } catch (error) {
    } finally {
      setCallingBackendCenter(false);
    }
  };

  const handlePreviousPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page - 1,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setPreviousCallingBackend(false);
    } catch (error) {}
  };
  const handleTwoPreviousPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page - 2,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setPreviousCallingBackend(false);
    } catch (error) {}
  };
  const handleWlePreviousPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page - 3,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setPreviousCallingBackend(false);
    } catch (error) {}
  };
  const handleNextPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page + 1,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setCallingBackend(false);
      setSkipCallingBackend(false);
    } catch (error) {}
  };
  const handleNextPumpPage = async (newpage) => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: newpage,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setCallingBackend(false);
      setSkipCallingBackend(false);
    } catch (error) {}
  };
  const handleWLENextPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page + 3,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setCallingBackend(false);
      setSkipCallingBackend(false);
    } catch (error) {}
  };
  const handleTwoNextPage = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page + 2,
      });
      const { data } = await axios.get(`/assesment/${state.id}`);
      setPage(data.form_number);
      setFormData(data);
      setCallingBackend(false);
      setSkipCallingBackend(false);
    } catch (error) {}
  };
  const handlePumpNumber = async () => {
    try {
      await axios.put(`/assesment/previous/${state.id}`, {
        form_number: page + 1,
      });
    } catch (error) {}
  };
  useEffect(() => {
    getPage();
    // eslint-disable-next-line
  }, []);
  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };
  const onHome = () => {
    navigate("/assessments-view");
  };
  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState({});
  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignContent: "flex-start",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <Grid
          container
          sx={{
            height: "100vh",
            position: "fixed",
            background: "#ffffff",
            opacity: 0.7,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "100%",
              objectFit: "cover",
            }}
            src={Background}
          />
        </Grid>
        <Grid
          container
          item
          xs={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
            height: "8rem",
            position: "absolute",
            borderRadius: "10px",
            backgroundColor: "#fff",
            opacity: 0.6,
            zIndex: 2,
          }}
        ></Grid>
        <Grid
          container
          item
          xs={11}
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "3rem",
            position: "absolute",
            zIndex: 3,
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1rem 1rem 1rem",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignContent: "center",
              }}
              item
            >
              <Drower
                page={page}
                getPage={getPage}
                formData={formData}
                setCallingBackend={setCallingBackendCenter}
              />
              <Button onClick={() => onHome()} sx={{ background: "none" }}>
                {" "}
                <img
                  src={Logo}
                  alt={"placed risk"}
                  style={{ width: "100px" }}
                />
              </Button>
            </Grid>
            <Button
              onClick={() => onLogout()}
              sx={{
                backgroundColor: "#22345B",
                textTransform: "capitalize",
                fontFamily: "serif",
                color: "#ffffff",
                borderRadius: "8px",
                ":hover": { backgroundColor: "#22345B" },
              }}
            >
              Logout
            </Button>
          </Grid>
          <FormStepperNav
            page={page}
            setPage={setPage}
            getPage={getPage}
            formData={formData}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            position: "absolute",
            paddingTop: "11rem",
          }}
        >
          <Grid
            container
            item
            xs={11}
            sx={{
              display: "flex",
              minHeight: "65vh",
              marginBottom: "2.5rem",
              flexDirection: "column",
              borderRadius: "10px",
              justifyContent: "center",
              alignContent: "center",
              backdropFilter: "blur(20px)",
              position: "relative",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 2,
              padding: "1rem",
            }}
          >
            {" "}
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                zIndex: 15,
              }}
            >
              {callingBackendcenter ? (
                <Grid
                  container
                  item
                  xs={10}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "2rem 0",
                    zIndex: 25,
                    height: "100vh",
                  }}
                >
                  <CircularProgress size={50} sx={{ color: "#22345B" }} />
                </Grid>
              ) : (
                <Pages
                  page={page}
                  getPage={getPage}
                  formData={formData}
                  handlePreviousPage={handlePreviousPage}
                  previouscallingBackend={previouscallingBackend}
                  handleWlePreviousPage={handleWlePreviousPage}
                  setPreviousCallingBackend={setPreviousCallingBackend}
                  handlePumpNumber={handlePumpNumber}
                  handleNextPage={handleNextPage}
                  handleWLENextPage={handleWLENextPage}
                  callingBackend={callingBackend}
                  setCallingBackend={setCallingBackend}
                  skipcallingBackend={skipcallingBackend}
                  setSkipCallingBackend={setSkipCallingBackend}
                  handleTwoPreviousPage={handleTwoPreviousPage}
                  handleTwoNextPage={handleTwoNextPage}
                  handleNextPumpPage={handleNextPumpPage}
                />
              )}
            </Grid>
          </Grid>
          {!callingBackend ? (
            <Grid
              container
              xs={12}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#22345B",
              }}
            >
              <Grid
                container
                xs={12}
                sx={{
                  bottom: 0,
                  zIndex: 100,
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  item
                  xs={11}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={prsdark} alt="prs-logo" width="12px" />
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        paddingLeft: "0.5rem",
                      }}
                    >
                      PlacedRisk Solutions Assessment Portal
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        paddingRight: "0.5rem",
                      }}
                    >
                      Designed, Developed and Maintained by
                    </Typography>
                    <img
                      src={olldark}
                      alt="ollcode-logo"
                      width="20px"
                      height="auto"
                    />
                    <a
                      href="https://ollcode.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "0.8rem",
                          fontWeight: 600,
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Ollcode
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              sx={{
                position: "fixed",
                bottom: 0,
                zIndex: 100,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#22345B",
              }}
            >
              <Grid
                container
                xs={12}
                sx={{
                  bottom: 0,
                  zIndex: 100,
                  padding: "8px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  item
                  xs={11}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img src={prsdark} alt="prs-logo" width="12px" />
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        paddingLeft: "0.5rem",
                      }}
                    >
                      PlacedRisk Solutions Assessment Portal
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ffffff",
                        fontSize: "0.8rem",
                        fontWeight: 500,
                        paddingRight: "0.5rem",
                      }}
                    >
                      Designed, Developed and Maintained by
                    </Typography>
                    <img
                      src={olldark}
                      alt="ollcode-logo"
                      width="20px"
                      height="auto"
                    />
                    <a
                      href="https://ollcode.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        sx={{
                          color: "#ffffff",
                          fontSize: "0.8rem",
                          fontWeight: 600,
                          paddingLeft: "0.5rem",
                        }}
                      >
                        Ollcode
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}{" "}
        </Grid>
      </Grid>
    </>
  );
}
