import React, { useState, useRef } from "react";
import customAxios from "../../libs/axios";
import axios from "axios";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useScreenshot } from "use-react-screenshot";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import RateChart from "../Stepper6/RateChart";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function ExecutiveSummary({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const { state } = useLocation();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [overallScore, setOverallScore] = useState(0);
  // const [occupancyStar, setOccupancyStar] = useState(0);
  // const [fire_d_p_Star, setFire_d_p_Star] = useState(0);
  // const [managementStar, setManagementStar] = useState(0);
  // const [specialStar, setSpecialStar] = useState(0);
  // const [naturalStar, setNaturalStar] = useState(0);

  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  const [description, setDescription] = useState(
    formData?.executive_summary?.occupancy_description
      ? formData?.executive_summary?.occupancy_description
      : ""
  );
  const [starheading1, setStarheading1] = useState(
    formData?.executive_summary?.heading1
      ? formData?.executive_summary?.heading1
      : ""
  );
  const [starheading2, setStarheading2] = useState(
    formData?.executive_summary?.heading2
      ? formData?.executive_summary?.heading2
      : ""
  );
  const [starheading3, setStarheading3] = useState(
    formData?.executive_summary?.heading3
      ? formData?.executive_summary?.heading3
      : ""
  );
  const [starheading4, setStarheading4] = useState(
    formData?.executive_summary?.heading4
      ? formData?.executive_summary?.heading4
      : ""
  );
   const [starheading5, setStarheading5] = useState(
     formData?.executive_summary?.heading5
       ? formData?.executive_summary?.heading5
       : ""
  );
   const [starheading6, setStarheading6] = useState(
     formData?.executive_summary?.heading6
       ? formData?.executive_summary?.heading6
       : ""
   );
  const [existingrisk, setExistingrisk] = useState(
    formData?.executive_summary?.existing_risk
      ? formData?.executive_summary?.existing_risk
      : ""
  );
  const [significantexposures, setSignificantexposures] = useState(
    formData?.executive_summary?.significant_exposures
      ? formData?.executive_summary?.significant_exposures
      : ""
  );
  const [significantchangesrecent, setSignificantchangesrecent] = useState(
    formData?.executive_summary?.significant_changes_recent
      ? formData?.executive_summary?.significant_changes_recent
      : ""
  );
  const [significantchangesfuture, setSignificantchangesfuture] = useState(
    formData?.executive_summary?.significant_changes_future
      ? formData?.executive_summary?.significant_changes_future
      : ""
  );

   const overallScoreStarString = formData?.executive_summary?.overallStar;
   const occupancyStarString = formData?.executive_summary?.occupancyStar;
   const fire_d_p_StarString = formData?.executive_summary?.fire_d_p_Star;
   const managementStarString = formData?.executive_summary?.managementStar;
   const specialStarString = formData?.executive_summary?.specialStar;
  const naturalStarStarString = formData?.executive_summary?.naturalStar;
  
  const submit = async (imageUrl) => {
    try {
      setCallingBackend(true);
      await customAxios.put(`/assesment/executive_summary/${state.id}`, {
        image: imageUrl,
        heading1: starheading1,
        heading2: starheading2,
        heading3: starheading3,
        heading4: starheading4,
        heading5: starheading5,
        heading6: starheading6,
        occupancy_description: description,
        existing_risk: existingrisk,
        significant_exposures: significantexposures,
        significant_changes_recent: significantchangesrecent,
        significant_changes_future: significantchangesfuture,
        overallStar: overallScoreStarString,
        occupancyStar: occupancyStarString,
        fire_d_p_Star: fire_d_p_StarString,
        managementStar: managementStarString,
        specialStar: specialStarString,
        naturalStar: naturalStarStarString,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Executive Summary performance created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Executive Summary performance created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (
        error.response.data === "Executive Summary performance already exists"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Executive Summary performance already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Executive Summary performance already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Executive Summary performance already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Executive Summary performance already exists!",
            }),
          5000
        );
      }
    }
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  const ref2 = useRef(null);
  // eslint-disable-next-line
  const [image2, takeScreenshot2] = useScreenshot({
    type: "image2/jpg",
    quality: 1.0,
  });
  const downloadImage2 = () => {
    setCallingBackend(true);
    takeScreenshot2(ref2.current).then((image) => {
      console.log("image", image);
      handleImageAsFile2(image); // Pass the image URL to the function
    });
  };

  const handleImageAsFile2 = async (image) => {
    console.log("image", image);
    try {
      // Convert image URL to Blob
      const response = await axios.get(image, { responseType: "blob" });
      const blob = response.data;

      // Create a unique filename
      const fileName = `image_${new Date().getTime()}.jpg`;

      // Create a reference to the file in Firebase Storage
      const storageRef = ref(storage, `/files/${fileName}`);

      // Upload the Blob
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("percent", percent); // Optional: You can use this to show upload progress
        },
        (err) => console.log("err>>>>>>>>>>>", err),
        () => {
          // Get download URL
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            submit(url);
          });
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  xs={12}
                  spacing={1}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ textAlign: "center", justifyContent: "center" }}
                  >
                    <p className="formName">Executive Summary</p>
                  </Grid>
                  <Grid container item xs={10} spacing={1}>
                    <div ref={ref2} style={{ width: "100%" }}>
                      <RateChart
                        formData={formData}
                        overallScore={overallScore}
                        setOverallScore={setOverallScore}
                        // setOccupancyStar={setOccupancyStar}
                        // setFire_d_p_Star={setFire_d_p_Star}
                        // setManagementStar={setManagementStar}
                        // setSpecialStar={setSpecialStar}
                        // setNaturalStar={setNaturalStar}
                      />
                    </div>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading1
                            ? starheading1
                            : `Aegros Ltd achieved an overall risk rating of ${overallScoreStarString} for this site. The progressive completion of risk improvements within this report would elevate this score. Please refer to the Risk Improvement Summary of this report for further detail.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading1}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading2
                            ? starheading2
                            : `The inherent Occupancy Risk Rating stands at ${occupancyStarString} Stars due to the combination of fire resistive construction classifications and extent of
a potential loss within the premises.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading2}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading3
                            ? starheading3
                            : `As there is limited automatic sprinkler protection installed on site, the Fire Detection and Protection achieved a ${fire_d_p_StarString} Rating.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading3}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading4
                            ? starheading4
                            : `Improving Management Practices is a priority for Management at Aegros. Management have committed to completing the relevant
Management Practices Risk Improvements to improve this score to ${managementStarString} within 90 days.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading4}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading5
                            ? starheading5
                            : `Special Hazards within this facility have mainly been confined to transformer separation and generator maintenance, both of which
are to be rectified during the upcoming refurbishment of the facility.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading5}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={
                          starheading6
                            ? starheading6
                            : `As no significant property damage or business interruption is expected to this site from Natural Hazards, it scored a ${naturalStarStarString} for this
area of assessment.`
                        }
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setStarheading6}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                      <p className="inputTextH">Occupancy Description</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={description || ""}
                        style={{ marginBottom: "80px", height: "150px" }}
                        onChange={setDescription}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                      <p className="inputTextH">
                        Existing Risk Reduction Attributes
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">
                        The following attributes have reduced the Overall risk
                        profile of this site:
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={existingrisk}
                        style={{ marginBottom: "80px", height: "200px" }}
                        onChange={setExistingrisk}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                      <p className="inputTextH">Significant Exposures</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">
                        The following exposures could lead to significant
                        property damage or business interuption on site:
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "30px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={significantexposures}
                        style={{ marginBottom: "25px", height: "200px" }}
                        onChange={setSignificantexposures}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: "3rem" }}>
                      <p className="inputTextH">Significant Changes</p>{" "}
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">
                        The following changes have been made on site in the
                        recent past:
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={significantchangesrecent}
                        style={{ marginBottom: "25px", height: "200px" }}
                        onChange={setSignificantchangesrecent}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <p className="inputTextP">
                        The following factors could impact this site in the
                        future:
                      </p>{" "}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: "100px" }}>
                      <ReactQuill
                        modules={module}
                        theme="snow"
                        value={significantchangesfuture}
                        style={{ marginBottom: "25px", height: "200px" }}
                        onChange={setSignificantchangesfuture}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} spacing={1}>
                    <div styles={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(formData?.executive_summary, null, 2)}
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={10}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Grid item>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#22345B",
                          textTransform: "capitalize",
                          fontFamily: "serif",
                          borderRadius: "8px",
                          ":hover": { backgroundColor: "#22345B" },
                        }}
                        onClick={() => downloadImage2()}
                        disabled={
                          !description ||
                          !existingrisk ||
                          !significantexposures ||
                          !significantchangesfuture ||
                          !significantchangesrecent ||
                          callingBackend
                        }
                      >
                        {callingBackend ? (
                          <CircularProgress
                            size={25}
                            sx={{ color: "#22345B", margin: "0 5px" }}
                          />
                        ) : (
                          "Next"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
