import React, { useState } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/NewTextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function TownMain({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
     const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
       width: "100%", // Ensures the DatePicker takes full width
       "& .MuiInputBase-root": {
         // Apply styles to the input root
         backgroundColor: "#FFFFFF",
         paddingTop: 0,
         marginTop: "-8px",
         transition: theme.transitions.create(["border-color", "box-shadow"]),
         width: "100%", // Ensures the input base takes full width
       },
       "& .MuiInputBase-input": {
         // Apply styles to the input text
         fontSize: 16,
         width: "100%", // Ensures the input text takes full width
         padding: "9px",
       },
     }));
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const { state } = useLocation();

  const inputArr = [
    {
      id: 0,
      sprinkler_flow: "",
      discharge: "",
    },
  ];
   const inputArr2 = [
     {
       id: 0,
       hydrant_flow: "",
       hydrant_test_location: "",
     },
   ];

  const [arr, setArr] = useState(
    formData?.town_main?.town_mains?.length ? [...formData?.town_main?.town_mains] :inputArr
  );
   const [arr2, setArr2] = useState(
     formData?.town_main?.hydrant?.length
       ? [...formData?.town_main?.hydrant]
       : inputArr2
   );
  const [number, setNumber] = useState(
    formData?.town_main?.town_mains?.length ? formData?.town_main?.town_mains?.length :1
  );
   const [number2, setNumber2] = useState(
     formData?.town_main?.hydrant?.length
       ? formData?.town_main?.hydrant?.length
       : 1
   );
  const [testdate, setTestdate] = useState(formData?.town_main?.test_date ? formData?.town_main?.test_date : "");
  const [watersource, setWatersource] = useState(formData?.town_main?.water_source ? formData?.town_main?.water_source : "");
  const [hydranttestdate, setHydranttestdate] = useState(formData?.town_main?.hydrant_test_date ? formData?.town_main?.hydrant_test_date : "");
  const [hydrantwatersource, setHydrantwatersource] = useState(formData?.town_main?.hydrant_water_source ? formData?.town_main?.hydrant_water_source : "");
  const [checkTM, setCheckTM] = useState(formData?.town_main?.town_mains_check);
  const [checkH, setCheckH] = useState(formData?.town_main?.hydrant_check);

    const handleTownsMain = (event) => {
      setCheckTM(event.target.checked);
  };
    const handleHydrant = (event) => {
      setCheckH(event.target.checked);
    };
  const addInput = () => {
    setNumber(number + 1);
    setArr((s) => {
      return [
        ...s,
        {
          id: number,
          sprinkler_flow: "",
          discharge: "",
        },
      ];
    });
  };
  const addInput2 = () => {
    setNumber2(number2 + 1);
    setArr2((s) => {
      return [
        ...s,
        {
          id: number2,
          hydrant_flow: "",
          hydrant_test_location: "",
        },
      ];
    });
  };
  const changeFormData = (value, name, id) => {
    const objIndex = arr.findIndex((obj) => obj.id === id);
    arr[objIndex][name] = value;

    setArr([...arr]);
  };
const changeFormDataArr2 = (value, name, id) => {
  const objIndex = arr2.findIndex((obj) => obj.id === id);
  arr2[objIndex][name] = value;

  setArr2([...arr2]);
};
  const submit = async (e) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/town_main/${state.id}`, {
        town_mains: arr,
        hydrant: arr2,
        test_date: dayjs(testdate).format("YYYY-MM-DD"),
        water_source: watersource,
        hydrant_test_date: dayjs(hydranttestdate).format("YYYY-MM-DD"),
        hydrant_water_source: hydrantwatersource,
        town_mains_check: checkTM,
        hydrant_check:checkH,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Town main created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Town main created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Town main already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Town main already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Town main already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Town main already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Town main already exists!",
            }),
          5000
        );
      }
    } 
  };

  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik enableReinitialize>
        {({ dirty, isValid }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="formName">Towns Main Water Supply</p>
                <Grid container xs={10} spacing={1}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        value="start"
                        control={
                          <Switch
                            checked={checkTM}
                            onChange={(e) => {
                              handleTownsMain(e);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Towns Main data disable"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextP">Test Date</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <StyledDatePicker
                            disabled={checkTM}
                            value={testdate ? dayjs(testdate) : null}
                            onChange={(newValue) => {
                              const formattedDate = newValue
                                ? newValue.format("YYYY-MM-DD")
                                : null;
                              setTestdate(formattedDate);
                            }}
                            format="YYYY-MM-DD"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                      <p className="inputTextP">Water Source</p>{" "}
                    </Grid>
                    <Grid item xs={9}>
                      <InputFeild
                        name="water_source"
                        onChange={(e) => setWatersource(e.target.value)}
                        value={watersource || ""}
                        component={TextField}
                        variant
                        fullWidth
                        bgColor="#FFFFFF"
                        margin="dense"
                        disabled={checkTM}
                      />{" "}
                    </Grid>
                    {/* <Grid item xs={12} sx={{ marginTop: "3rem" }}>
                      <p className="formName">Town Main</p>
                    </Grid> */}

                    <Grid container item xs={6} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Sprinkler Flow (L/min)</p>{" "}
                      </Grid>
                    </Grid>
                    <Grid container item xs={6} spacing={1}>
                      <Grid item xs={12}>
                        <p className="inputTextH">Discharge (kPa)</p>{" "}
                      </Grid>
                    </Grid>
                    {arr.map((item, i) => {
                      return (
                        <>
                          <Grid container item xs={6} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="sprinkler_flow"
                                value={item.sprinkler_flow}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "sprinkler_flow",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                                disabled={checkTM}
                              />{" "}
                            </Grid>
                          </Grid>
                          <Grid container item xs={6} spacing={1}>
                            <Grid item xs={12}>
                              <InputFeild
                                name="discharge"
                                value={item.discharge}
                                onChange={(e) =>
                                  changeFormData(
                                    e.target.value,
                                    "discharge",
                                    item.id
                                  )
                                }
                                component={TextField}
                                variant
                                fullWidth
                                bgColor="#FFFFFF"
                                margin="dense"
                                type="number"
                                disabled={checkTM}
                              />{" "}
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                    <Button
                      onClick={addInput}
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        marginTop: "1.5rem",
                        marginLeft: "0.5rem",
                      }}
                      disabled={
                        number >= 10 ||
                        !arr.every(
                          (item) =>
                            !Object.entries(item).some(
                              ([key, value]) => value === ""
                            )
                        )
                      }
                    >
                      Add Field
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "3rem" }}>
                    <p className="formName">Hydrants</p>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch
                          checked={checkH}
                          onChange={(e) => {
                            handleHydrant(e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Hydrants data disable"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <p className="inputTextP">Test Date</p>{" "}
                  </Grid>
                  <Grid item xs={9}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <StyledDatePicker
                          value={
                            hydranttestdate ? dayjs(hydranttestdate) : null
                          }
                          disabled={checkH}
                          onChange={(newValue) => {
                            const formattedDate = newValue
                              ? newValue.format("YYYY-MM-DD")
                              : null;
                            setHydranttestdate(formattedDate);
                          }}
                          format="YYYY-MM-DD"
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <p className="inputTextP">Water Source</p>{" "}
                  </Grid>
                  <Grid item xs={9}>
                    <InputFeild
                      name="hydrant_test_date"
                      onChange={(e) => setHydrantwatersource(e.target.value)}
                      value={hydrantwatersource || ""}
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                      disabled={checkH}
                    />{" "}
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <p className="inputTextH">Hydrant Flow (L/min)</p>{" "}
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                      <p className="inputTextH">Hydrant Test Location (kPa)</p>{" "}
                    </Grid>
                  </Grid>
                  {arr2.map((item, i) => {
                    return (
                      <>
                        <Grid container item xs={6} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="hydrant_flow"
                              value={item.hydrant_flow}
                              onChange={(e) =>
                                changeFormDataArr2(
                                  e.target.value,
                                  "hydrant_flow",
                                  item.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              type="number"
                              disabled={checkH}
                            />{" "}
                          </Grid>
                        </Grid>
                        <Grid container item xs={6} spacing={1}>
                          <Grid item xs={12}>
                            <InputFeild
                              name="hydrant_test_location"
                              value={item.hydrant_test_location}
                              onChange={(e) =>
                                changeFormDataArr2(
                                  e.target.value,
                                  "hydrant_test_location",
                                  item.id
                                )
                              }
                              component={TextField}
                              variant
                              fullWidth
                              bgColor="#FFFFFF"
                              margin="dense"
                              type="number"
                              disabled={checkH}
                            />{" "}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                  <Button
                    onClick={addInput2}
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      marginTop: "1.5rem",
                      marginLeft: "0.5rem",
                    }}
                    disabled={
                      number2 >= 10 ||
                      !arr2.every(
                        (item) =>
                          !Object.entries(item).some(
                            ([key, value]) => value === ""
                          )
                      )
                    }
                  >
                    Add Field
                  </Button>
                  <Grid item xs={12} spacing={1}>
                    <pre>{JSON.stringify(formData?.town_main, null, 2)}</pre>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      onClick={() => submit()}
                      disabled={
                        (!checkTM &&
                          !arr.every(
                            (item) =>
                              !Object.entries(item).some(
                                ([key, value]) => value === ""
                              )
                          )) ||
                        (!checkTM && !testdate) ||
                        (!checkTM && !watersource) ||
                        (!checkH &&
                          !arr2.every(
                            (item) =>
                              !Object.entries(item).some(
                                ([key, value]) => value === ""
                              )
                          )) ||
                        (!checkH && !hydrantwatersource) ||
                        (!checkH && !hydranttestdate) ||
                        number === 0 ||
                        number2 === 0 ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
