import React, { useState, useEffect, Fragment } from "react";
import axios from "../../libs/axios";
import { Formik, Form } from "formik";
import TextField from "@mui/material/TextField";
import InputFeild from "../../components/common/TextFeild";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import "../Assesment/assesment.css";
import { Button } from "@mui/material";
import { validationSchema } from "../../utils/validations/fireArea";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../firebase/firebase";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

// const constructionLabels = [
//   "Fire Resistive (reinforced concrete, brick, concrete block, brick veneer, stone)",
//   "Non Combustible (steel on steel frame)",
//   "Semi Conbustible with no EPS (fire resistive or non combustible construction with some wood or other inferior construction materials)",
//   "Mixed with No EPS (fire resistive with wood and other inferior construction)",
//   "Mixed with EPS (fire resistive with wood and other inferior construction with less than 25% EPS)",
//   "Combustible (less than 25% EPS)",
//   "Highly Combustible (greater than 25% EPS)",
// ];

const labels = ["Adequate", "Inadequate"];

export default function FireArea({
  formData,
  handlePreviousPage,
  previouscallingBackend,
  setPreviousCallingBackend,
  handleNextPage,
  callingBackend,
  setCallingBackend,
}) {
  const [defaultEarth_file, setDefaultEarth_file] = useState(false);
  const { state } = useLocation();

  const [ccategory, setCcategory] = useState("");
  const [fireArea1, setFireArea1] = useState("");
  const [fireArea2, setFireArea2] = useState("");
  const [fireArea3, setFireArea3] = useState("");

  const [initialValues, setInitialValues] = useState({
    earth_file:
      "https://firebasestorage.googleapis.com/v0/b/image-upload-6851d.appspot.com/o/files%2Fistockphoto.jpgThu%20Mar%2021%202024%2008%3A46%3A30%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=4b12671b-2d24-49e8-b7fb-c06c34727bc7",

    overall_c_category: "",
    overall_c_category_description: "",
    space_separation: "",
    space_separation_description: "",
    fire_Walls: "",
    fire_Walls_description: "",
    combustible_continuity: "",
    combustible_continuity_description: "",
  });
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const submit = async (e, { resetForm }) => {
    try {
      setCallingBackend(true);
      await axios.put(`/assesment/fire-area/${state.id}`, {
        earth_file: e.earth_file,
        overall_c_category: ccategory || initialValues.overall_c_category,
        overall_c_category_description: e.overall_c_category_description,
        space_separation: fireArea1 || initialValues.space_separation,
        space_separation_description: e.space_separation_description,
        fire_Walls: fireArea2 || initialValues.fire_Walls,
        fire_Walls_description: e.fire_Walls_description,
        combustible_continuity:
          fireArea3 || initialValues.combustible_continuity,
        combustible_continuity_description:
          e.combustible_continuity_description,
      });
      setAlert({
        showAlert: true,
        severity: "success",
        message: "Fire area details created successfully!",
      });
      setTimeout(
        () =>
          setAlert({
            showAlert: false,
            severity: "success",
            message: "Fire area details created successfully!",
          }),
        handleNextPage(),
        5000
      );
    } catch (error) {
      if (error.response.data === "Fire area details already exists") {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire area details already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire area details already exists!",
            }),
          5000
        );
      } else if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized Access!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Unauthorized Access!",
            }),
          5000
        );
      } else if (error.response.status === 500) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server Error!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Server Error!",
            }),
          5000
        );
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Fire area details already exists!",
        });
        setTimeout(
          () =>
            setAlert({
              showAlert: false,
              severity: "success",
              message: "Fire area details already exists!",
            }),
          5000
        );
      }
    }
  };

  useEffect(() => {
    setInitialValues({
      earth_file: formData?.fire_area_page?.earth_file,
      overall_c_category: formData?.fire_area_page?.overall_c_category,
      overall_c_category_description:
        formData?.fire_area_page?.overall_c_category_description,
      space_separation: formData?.fire_area_page?.space_separation,
      space_separation_description:
        formData?.fire_area_page?.space_separation_description,
      fire_Walls: formData?.fire_area_page?.fire_Walls,
      fire_Walls_description: formData?.fire_area_page?.fire_Walls_description,
      combustible_continuity: formData?.fire_area_page?.combustible_continuity,
      combustible_continuity_description:
        formData?.fire_area_page?.combustible_continuity_description,
    });
    setCcategory(formData?.fire_area_page?.overall_c_category);
    setFireArea1(formData?.fire_area_page?.space_separation);
    setFireArea2(formData?.fire_area_page?.fire_Walls);
    setFireArea3(formData?.fire_area_page?.combustible_continuity);
  }, [formData]);

  const handleImageAsFile = async (e, setFieldValue, type, values) => {
    try {
      setDefaultEarth_file(true);

      const earth_file = e.target.files[0];
      const storageRef = ref(
        storage,
        `/files/${earth_file.name}${earth_file.lastModifiedDate}`
      );
      const uploadTask = uploadBytesResumable(storageRef, earth_file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // eslint-disable-next-line
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setFieldValue("earth_file", url);
          });
        }
      );
    } catch (error) {
    } finally {
      setDefaultEarth_file(false);
    }
  };
  const handleClick = () => {
    setPreviousCallingBackend(true);
    handlePreviousPage();
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ dirty, isValid, values, setFieldValue }) => {
          return (
            <Form>
              <Grid
                container
                xs={12}
                sx={{
                  padding: "1rem",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <p className="formName">Fire Area</p>
                <p className="inputTextH" style={{paddingBottom:'1rem'}}>Map Upload</p>
                <Grid container xs={10} spacing={1}>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      boxShadow: 3,
                      borderRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={10}
                      sx={{
                        borderColor: "#eeeeee",
                        borderStyle: "dashed",
                        padding: "1rem 0",
                        margin: "2rem 0",
                      }}
                    >
                      <Grid
                        container
                        item
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {defaultEarth_file ? (
                          "Uploading..."
                        ) : (
                          <Fragment>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              {values.earth_file ? (
                                <img
                                  src={values.earth_file}
                                  alt="earth_file"
                                  style={{
                                    maxHeight: "200px",
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/image-upload-6851d.appspot.com/o/files%2Fistockphoto.jpgThu%20Mar%2021%202024%2008%3A46%3A30%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=4b12671b-2d24-49e8-b7fb-c06c34727bc7"
                                  }
                                  alt="earth_file"
                                  style={{
                                    maxHeight: "50px",
                                  }}
                                />
                              )}
                              {/* {values.earth_file !== "" &&
                              values.earth_file !==
                                "https://firebasestorage.googleapis.com/v0/b/image-upload-6851d.appspot.com/o/files%2Fistockphoto.jpgThu%20Mar%2021%202024%2008%3A46%3A30%20GMT%2B0530%20(India%20Standard%20Time)?alt=media&token=4b12671b-2d24-49e8-b7fb-c06c34727bc7" ? (
                                <Box display="inline">
                                  <Button
                                    color="inherit"
                                    variant="contained"
                                    component="span"
                                    size="small"
                                    style={{
                                      marginBottom: "1rem",
                                      marginTop: "1rem",
                                    }}
                                    onClick={(e) => removeImage(setFieldValue)}
                                  >
                                    Remove image
                                  </Button>
                                </Box>
                              ) : (
                                ""
                              )} */}
                            </div>
                            <div>
                              <label htmlFor="upload-photo">
                                <input
                                  style={{ display: "none", marginTop: "1rem" }}
                                  id="upload-photo"
                                  name="upload-photo"
                                  type="file"
                                  accept="image/jpeg,image/png"
                                  onChange={(e) => {
                                    e.persist();
                                    handleImageAsFile(
                                      e,
                                      setFieldValue,
                                      "earth_file",
                                      values
                                    );
                                  }}
                                />
                                <Button
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  size="small"
                                  sx={{
                                    marginBottom: "1rem",
                                    marginTop: "1rem",
                                    backgroundColor: "#22345B",
                                    textTransform: "capitalize",
                                    fontFamily: "serif",
                                    borderRadius: "8px",
                                    ":hover": { backgroundColor: "#22345B" },
                                  }}
                                >
                                  upload
                                </Button>
                              </label>
                            </div>
                          </Fragment>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid
                  width={"100%"}
                  sx={{
                    marginTop: "5rem",
                    padding: "1px",
                    backgroundColor: "#808080",
                  }}
                ></Grid>
                <p className="formName">Fire Area Details</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={4}>
                    <p className="inputTextP">Overall Construction Category</p>
                  </Grid>
                  <Grid item xs={8}>
                    <Autocomplete
                      hiddenLabel
                      id="overall_c_category"
                      name="overall_c_category"
                      size="small"
                      sx={{ marginBottom: "5px" }}
                      value={ccategory || initialValues.overall_c_category}
                      options={constructionLabels}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setCcategory(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <InputFeild
                      Placeholder={"Enter up to 200 words"}
                      multiline
                      rows={4}
                      name="overall_c_category_description"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                </Grid>*/}
                <Grid
                  width={"100%"}
                  sx={{
                    marginTop: "5rem",
                    padding: "1px",
                    backgroundColor: "#808080",
                  }}
                ></Grid>
                <p className="formName">Fire Separation</p>
                <Grid container xs={10} spacing={1}>
                  <Grid item xs={4}>
                    <p className="inputTextP">Space Separation</p>
                    <Autocomplete
                      hiddenLabel
                      id="space_separation"
                      name="space_separation"
                      size="small"
                      sx={{ marginBottom: "5px" }}
                      value={fireArea1 || initialValues.space_separation}
                      options={labels}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setFireArea1(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <InputFeild
                      Placeholder={"Enter up to 200 words"}
                      multiline
                      rows={4}
                      name="space_separation_description"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <p className="inputTextP">Fire Walls</p>
                    <Autocomplete
                      hiddenLabel
                      id="fire_Walls"
                      name="fire_Walls"
                      size="small"
                      sx={{ marginBottom: "5px" }}
                      value={fireArea2 || initialValues.fire_Walls}
                      options={labels}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setFireArea2(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <InputFeild
                      Placeholder={"Enter up to 200 words"}
                      multiline
                      rows={4}
                      name="fire_Walls_description"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <p className="inputTextP">Combustible Continuity</p>
                    <Autocomplete
                      hiddenLabel
                      id="combustible_continuity"
                      name="combustible_continuity"
                      size="small"
                      sx={{ marginBottom: "5px" }}
                      value={fireArea3 || initialValues.combustible_continuity}
                      options={labels}
                      getOptionLabel={(option) => option}
                      onChange={(event, newValue) => {
                        setFireArea3(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                    <InputFeild
                      name="combustible_continuity_description"
                      component={TextField}
                      variant
                      fullWidth
                      bgColor="#FFFFFF"
                      margin="dense"
                      Placeholder={"Enter up to 200 words"}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  {/* <Grid item xs={6} spacing={1}>
                    <pre>
                      {JSON.stringify(formData?.fire_area_page, null, 2)}
                    </pre>
                  </Grid> */}
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      alignContent: "center",
                      justifyContent: "space-between",
                      marginTop: "2rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleClick()}
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={previouscallingBackend}
                    >
                      {previouscallingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Previous"
                      )}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        backgroundColor: "#22345B",
                        textTransform: "capitalize",
                        fontFamily: "serif",
                        borderRadius: "8px",
                        ":hover": { backgroundColor: "#22345B" },
                      }}
                      disabled={
                        !isValid ||
                        !fireArea1 ||
                        !fireArea2 ||
                        !fireArea3 ||
                        callingBackend
                      }
                    >
                      {callingBackend ? (
                        <CircularProgress
                          size={25}
                          sx={{ color: "#22345B", margin: "0 5px" }}
                        />
                      ) : (
                        "Next"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
