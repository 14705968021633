import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
// import { makeStyles } from "@mui/material";
import { login, logout } from "../../screens/store/actions/authActions";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import styles from "../../utils/styles/Login.module.css";
import { useNavigate } from "react-router-dom";
import prsdark from "../../assets/img/Dark_logomark.png";
import olldark from "../../assets/img/Logomark_W.png";
// validation
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import CircularProgress from "@mui/material/CircularProgress";
// import FormikField from "../formikField/FormikField";
import { TextField } from "formik-material-ui";
import axios from "../../libs/axios";
import { useEffect } from "react";
import "./styles.css";
import "./assesment.css";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     marginTop: theme.spacing(1),
//     height: "100%",
//   },
//   center: {
//     textAlign: "center",
//     fontSize: "2rem",
//     fontWeight: "500",
//   },
//   padding: {
//     padding: theme.spacing(3),
//   },
//   loginbtn: {
//     backgroundColor: "#4B7B6B",
//     color: "#ffffff",
//     margin: "1rem 0",
//     "&:hover": {
//       backgroundColor: "#4B7B6B",
//     },
//   },
// }));

let initialValues = {
  email: "",
  password: "",
};

let SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required!")
    .trim()
    .matches(
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email"
    )
    .max(320, "Must be less than 320 digits"),
  password: Yup.string()
    .required("Password is required!")
    .trim()
    .max(8, "Must Contain 8 Characters"),
});
function Login(props) {
  useEffect(() => {
    props.logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const classes = useStyles();
  const [callingBackend, setCallingBackend] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  let navigate = useNavigate();

  const submit = async (e) => {
    try {
      setCallingBackend(true);
      const data = await axios.post("/user/login/", {
        email: e.email,
        password: e.password,
      });
      const { fullName, role, accessToken, permissions, id } = data.data;

      props.login(fullName, role, accessToken, permissions, id);
      navigate("/assessments-view");
      setCallingBackend(false);
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Unauthorized!",
        });
      } else if (
        error.response.data ===
        "User status not set, please contact administrator"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "User status not set, please contact administrator!",
        });
      } else if (
        error.response.data ===
        "You are temporary block, please contact administrator"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "You are temporary block, please contact administrator!",
        });
      } else if (
        error.response.data ===
        "You are status not active yet, please contact administrator"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message:
            "You are status not active yet, please contact administrator!",
        });
      } else if (
        error.response.data ===
        "You are status inactive yet, please contact administrator"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "You are status inactive yet, please contact administrator!",
        });
      } else if (
        error.response.data ===
        "Your password is not set, please contact administrator"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Your password is not set, please contact administrator!",
        });
      } else if (
        error.response.data === "Please check your email and password"
      ) {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Please check your email and password!",
        });
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Server error!",
        });
      }
    } finally {
      setCallingBackend(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Grid container>
      <Grid
        container
        item
        className="mainscreenview"
        sx={{
          height: "100vh",
          position: "absolute",
          zIndex: -1,
        }}
      >
        {" "}
      </Grid>
      <Grid container className="mainscreen">
        <div className={styles.Wrapper}>
          <div className={styles.Right}>
            <div className={styles.Login}>
              <Grid item md={12}>
                <Card style={{ padding: "3rem", zIndex: 15 }}>
                  <Typography style={{ fontSize: "2rem", textAlign: "center" }}>
                    Login
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={SignUpSchema}
                  >
                    {({ dirty, isValid }) => {
                      return (
                        <Form>
                          <CardContent>
                            <Field
                              name="email"
                              label="Email"
                              component={TextField}
                              variant="outlined"
                              fullWidth
                              margin="dense"
                              sx={{
                                width: "100%",
                                fontFamily: "serif",
                              }}
                              hiddenLabel
                              size="small"
                              id="filled-hidden-label-small"
                            ></Field>
                            <Field
                              fullWidth
                              name="password"
                              placeholder="Password"
                              required
                              type={showPassword ? "text" : "password"}
                              variant="outlined"
                              component={TextField}
                              sx={{
                                width: "100%",
                                fontFamily: "serif",
                              }}
                              hiddenLabel
                              size="small"
                              id="filled-hidden-label-small"
                              margin="dense"
                              helperText={
                                <ErrorMessage name="password"></ErrorMessage>
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                    >
                                      {showPassword ? (
                                        <VisibilityOutlinedIcon
                                          sx={{ color: "#79747E" }}
                                        />
                                      ) : (
                                        <VisibilityOffOutlinedIcon
                                          sx={{ color: "#79747E" }}
                                        />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </CardContent>

                          <CardActions>
                            <Button
                              variant="contained"
                              fullWidth
                              disabled={!dirty || !isValid || callingBackend}
                              type="submit"
                              sx={{
                                backgroundColor: "#22345B",
                                textTransform: "capitalize",
                                fontFamily: "serif",
                                borderRadius: "8px",
                                ":hover": { backgroundColor: "#22345B" },
                              }}
                            >
                              {callingBackend ? (
                                <CircularProgress
                                  size={25}
                                  sx={{ color: "#22345B", margin: "0 5px" }}
                                />
                              ) : (
                                "login"
                              )}
                            </Button>
                          </CardActions>
                          <Typography
                            style={{
                              textAlign: "center",
                              color: "#000",
                              fontSize: "0.9rem",
                            }}
                          >
                            Have a trouble accessing your account? Contact
                            support@ollcode.com
                          </Typography>
                        </Form>
                      );
                    }}
                  </Formik>
                </Card>
              </Grid>
              {alert.showAlert && (
                <Grid item md={12} style={{ marginTop: "0.5rem" }}>
                  <Alert
                    severity={alert.severity}
                    onClose={() =>
                      setAlert({
                        ...alert,
                        showAlert: false,
                      })
                    }
                  >
                    {alert.message}
                  </Alert>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          position: "fixed",
          bottom: 0,
          zIndex: 100,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#22345B",
        }}
      >
        <Grid
          container
          xs={12}
          sx={{
            bottom: 0,
            zIndex: 100,
            padding: "8px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            item
            xs={11}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
              }}
            >
              <img src={prsdark} alt="prs-logo" width="12px" />
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  paddingLeft: "0.5rem",
                }}
              >
                PlacedRisk Solutions Assessment Portal
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontSize: "0.8rem",
                  fontWeight: 500,
                  paddingRight: "0.5rem",
                }}
              >
                Designed, Developed and Maintained by
              </Typography>
              <img
                src={olldark}
                alt="ollcode-logo"
                width="20px"
                height="auto"
              />
              <a
                href="https://ollcode.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontSize: "0.8rem",
                    fontWeight: 500,
                    paddingLeft: "0.5rem",
                  }}
                >
                  Ollcode
                </Typography>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default connect(null, { login, logout })(Login);
